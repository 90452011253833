import styled from 'styled-components';

export const THeadCell = styled.th`
  padding: 16px;
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  vertical-align: middle;
  border-right: 1.5px solid #d3aa3b;

  &:last-child {
    border-right: none;
  }
`;
