import styled from 'styled-components';

export const Textarea = styled.textarea<{ $width?: string; $height?: string }>`
  width: ${({ $width }) => $width ?? '100%'};
  height: ${({ $height }) => $height ?? '200px'};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 16px 13px;
  resize: none;
  font-family: Helvetica Neue, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.darkGray};
`;
