import styled from 'styled-components';

export const TBodyCell = styled.td<{ $background?: string; $padding?: string }>`
  vertical-align: middle;
  padding: ${({ $padding }) => $padding ?? '12px 16px'};
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  border-right: 1.5px solid #d3aa3b;
  ${({ $background }) => $background && `background: ${$background};`}

  &:last-child {
    border-right: none;
  }
`;
